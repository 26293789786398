@import '../../style/themes/index';
@import '../../style/mixins/index';

@image-prefix-cls: ~'@{ant-prefix}-image';
@image-preview-prefix-cls: ~'@{image-prefix-cls}-preview';

.@{image-prefix-cls} {
  position: relative;
  display: inline-block;
  &-img {
    width: 100%;
    height: auto;
    &-placeholder {
      background-color: @image-bg;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30%;
    }
  }

  &-placeholder {
    .box;
  }

  &-preview {
    height: 100%;
    text-align: center;

    &-body {
      .box;
      overflow: hidden;
    }

    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      transform: scale3d(1, 1, 1);
      cursor: grab;
      transition: transform 0.3s @ease-out 0s;
      user-select: none;
      pointer-events: auto;
      &-wrapper {
        .box;
        transition: transform 0.3s @ease-out 0s;
        &::before {
          display: inline-block;
          width: 1px;
          height: 50%;
          margin-right: -1px;
          content: '';
        }
      }
    }

    &-moving {
      .@{image-prefix-cls}-preview-img {
        cursor: grabbing;
        &-wrapper {
          transition-duration: 0s;
        }
      }
    }

    &-wrap {
      z-index: @zindex-image;
    }

    &-operations {
      .reset-component;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      color: @image-preview-operation-color;
      list-style: none;
      background: fade(@modal-mask-bg, 10%);
      pointer-events: auto;

      &-operation {
        margin-left: @control-padding-horizontal;
        padding: @control-padding-horizontal;
        cursor: pointer;
        &-disabled {
          color: @image-preview-operation-disabled-color;
          pointer-events: none;
        }
        &:last-of-type {
          margin-left: 0;
        }
      }
      &-icon {
        font-size: @image-preview-operation-size;
      }
    }
  }
}

@primary-color: #002C54;@link-color: #1d64a6;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@layout-header-height: 80px;@layout-header-padding: 20px 72px;@layout-header-background: @primary-color;@layout-header-line-height: 40px;@layout-body-background: #FFF;@heading-1-size: 46px;@heading-2-size: 38px;@heading-4-size: 24px;@heading-5-size: 20px;@btn-padding-horizontal-base: 25px;@progress-default-color: @link-color;@radio-dot-color: @link-color;@checkbox-color: @link-color;@font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';